<template>
  <div class="password-step">
    <upper-text class="question">
      Crie uma senha segura
    </upper-text>

    <form-builder
      class="form"
      :fields="$options.fields"
      :value="{ password: value }"
      :id="$options.formInfo.id"
      @input="data => $emit('input', data.password)"
      @submit="({ handleSubmit }) => handleSubmit(onSubmit)"
    >
      <div slot="after-fields" class="after-fields">
        <span class="requirements">
          Ao definir minha senha, eu aceito os
          <a class="link" :href="`${homeUrl}/termos`">termos da empresa</a>
          e a
          <a class="link" :href="`${homeUrl}/politica-de-privacidade`">
            política de serviço.
          </a>
        </span>

        <prev-next slot="after-fields" :loading="loading" step-num="4" @click="onPrev">
          finalizar
        </prev-next>
      </div>
    </form-builder>
  </div>
</template>

<script>
import FormBuilder from '@components/form/Builder'
import fields from '@content/forms/password'
import { password as formInfo } from '@content/forms/ids/index'
import Step from './Step'
import UpperText from './UpperText'
import PrevNext from './PrevNext'

export default {
  mixins: [ Step ],

  props: { loading: Boolean },

  fields,
  formInfo,

  components: {
    FormBuilder,
    UpperText,
    PrevNext
  },

  inject: [ 'homeUrl' ]
}
</script>

<style lang="scss">
.password-step {
  & > .upper-text {
    margin-bottom: 20px;
  }

  & > .form > .after-fields > .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    & > .submit-button {
      width: 240px;
    }
  }

  & > .form > .after-fields {
    margin-top: 40px;
  }

  & > .form > .after-fields > .requirements {
    display: inline-block;
    max-width: 410px;
    margin-bottom: 16px;

    color: rgba($base-text-color, 0.7);
    font-family: $font-family-nunito-regular;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;

    & > .link {
      display: inline-block;
      height: 21px;
      font-family: $font-family-nunito-bold;
      color: rgba($base-text-color, 0.7);
      border-bottom: 1px dotted;
      transition: color 300ms;

      &:visited, &:hover, &:active {
        color: rgba($base-text-color, 0.9);
      }
    }
  }
}
</style>
